<!--
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2020-12-27 20:41:48
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-02 20:29:34
 * @FilePath: /refactor-with-vue3/src/views/home/index.vue
-->
<template>
  <div  class="main">

  </div>
</template>

<script>
import { onMounted, toRefs, getCurrentInstance, } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";



import {
  changeToDate,
  clearToast,
  formatDate, getStore, loginToPlatform,
  setStore,
  showFailToast,
  showLoading,
  showSuccessToast
} from "../../utils/util";


export default {
  name: "login",
  components: {

  },
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    const $store = useStore();
    const $router = useRouter();

    onMounted(() => {
      getHwToken()

    })


    window['transferUserDataToWeb'] = (uid,urid,token) => {

      let param = {
        uid: uid,
        urid: urid,
        token: token
      }
      showLoading('登录中')
      proxy.$http.post("/wenwu-user/user/green/channel/hongwang", param).then(res => {
        if(res.data.code === 200){
          clearToast()
          showSuccessToast('登录成功')
          setStore("token", res.data.data.satoken)
          setStore("isValid", res.data.data.isValid)
          // setTimeout(() => {
          //   location.reload()
          // }, 1000);
          $router.replace('/index')
        }else{
          showFailToast('登录失败')
        }

      }).catch((err)=>{
        // state.errorstr = JSON.stringify(err);
        showFailToast('登录失败')
      })
    }

    const getHwToken = (() => {
      localStorage.removeItem('token')
      localStorage.removeItem('isValid')

        //获取红网登录令牌
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
          verifyJsBridge.postNotification('requireUserToLogin', '{}');
        } else if(isiOS) {
          window.webkit.messageHandlers.requireUserToLogin.postMessage('');
        }

    })






    return {
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>

